import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table'
import Image from 'react-bootstrap/Image'
import Accordion from 'react-bootstrap/Accordion'
import Moment from 'moment';

function App() {
  const [data, setData] = useState([]);

  useEffect(async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
      // body: JSON.stringify({ title: 'React POST Request Example' })
  };
    Moment.locale('en');
    console.log('endpoint ' + 'https://kw5rmipt43.execute-api.eu-north-1.amazonaws.com/v1/status' + window.location.search)
    const result = await fetch('https://kw5rmipt43.execute-api.eu-north-1.amazonaws.com/v1/status' + window.location.search, requestOptions);
    const body = await result.json();
    setData(body);
  }, []);
  
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-intro">
          <Accordion>
          {data && data.map(element =>
          <Accordion.Item eventKey={element.store.id}>
            <div >
            <Accordion.Header>{element.store.name + ' - ' + element.store.address.street + ' ' + element.store.address.city + ' ' + element.store.address.zip}</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Duration (last update)</th>
                  <th>Original Price</th>
                  <th>New Price</th>
                  <th>Stock</th>
                  <th>Discount</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
              {element.clearances.map(clr => 
                <>
                <tr>
                  <td>{clr.product.description} {"\n"} {clr.product.description}</td>
                  <td>{Moment(clr.offer.startTime).format('D MMM') + ' - ' + Moment(clr.offer.endTime).format('D MMM')}
                  <p>
                  {'(last update '  + Moment(clr.offer.lastUpdate).format('D MMM HH:MM:SS YYYY') + ')'}
                  </p>
                  </td>
                  <td>{clr.offer.originalPrice + ' ' + clr.offer.currency}</td>
                  <td>{clr.offer.newPrice+ ' ' + clr.offer.currency}</td>
                  <td>{clr.offer.stock}</td>
                  <td>{clr.offer.percentDiscount + '% (' + clr.offer.discount + ' ' + clr.offer.currency + ')' }</td>
                  <td>{clr.product.image && <Image  fluid thumbnail src={clr.product.image} style={{width: 100 + 'px',heiht: 100 + 'px',  }} />}</td>
                </tr>
                </>
              )}
              </tbody>
              </Table>
              </Accordion.Body>
            </div>
            </Accordion.Item>
          )}
          </Accordion>
        </div>
   
      </header>
    </div>
  );
}

export default App;
